import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../../store/reducers/domainTheme";
import { setColorPalette } from "../../../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function Image3(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="456"
      height="325"
      viewBox="0 0 456 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18699_43046)">
        <rect
          width="456"
          height="325"
          rx="12"
          fill={colors?.secondaryBackground}
        />
        <path
          d="M49.4004 106.876C49.4004 102.864 49.4004 100.858 50.3405 99.3487C51.0352 98.2328 52.0753 97.3146 53.3391 96.7011C55.049 95.8711 57.3208 95.8711 61.8644 95.8711H272.536C277.08 95.8711 279.352 95.8711 281.062 96.7011C282.326 97.3146 283.366 98.2328 284.06 99.3487C285 100.858 285 102.864 285 106.876V278.279C285 282.291 285 284.297 284.06 285.807C283.366 286.922 282.326 287.841 281.062 288.454C279.352 289.284 277.08 289.284 272.536 289.284H61.8644C57.3208 289.284 55.049 289.284 53.3391 288.454C52.0753 287.841 51.0352 286.922 50.3405 285.807C49.4004 284.297 49.4004 282.291 49.4004 278.279V106.876Z"
          fill={colors?.bg_color}
        />
        <path
          d="M248.583 109.692C248.583 107.422 248.583 106.287 249.053 105.433C249.4 104.801 249.92 104.282 250.552 103.934C251.407 103.465 252.543 103.465 254.815 103.465H271.066C273.338 103.465 274.474 103.465 275.329 103.934C275.961 104.282 276.481 104.801 276.828 105.433C277.298 106.287 277.298 107.422 277.298 109.692V112.425C277.298 114.695 277.298 115.83 276.828 116.685C276.481 117.316 275.961 117.836 275.329 118.183C274.474 118.652 273.338 118.652 271.066 118.652H254.815C252.543 118.652 251.407 118.652 250.552 118.183C249.92 117.836 249.4 117.316 249.053 116.685C248.583 115.83 248.583 114.695 248.583 112.425V109.692Z"
          fill={colors?.primary_color}
        />
        <path
          d="M256.184 111.059C256.184 108.962 257.885 107.262 259.984 107.262H266C268.099 107.262 269.8 108.962 269.8 111.059C269.8 113.156 268.099 114.855 266 114.855H259.984C257.885 114.855 256.184 113.156 256.184 111.059Z"
          fill={colors?.bg_color}
        />
        <path
          d="M292.6 106.081C292.6 102.013 292.6 99.9791 293.54 98.4481C294.234 97.3167 295.274 96.3855 296.538 95.7635C298.248 94.9219 300.52 94.9219 305.064 94.9219H394.136C398.679 94.9219 400.951 94.9219 402.661 95.7635C403.925 96.3855 404.965 97.3167 405.66 98.4481C406.6 99.9791 406.6 102.013 406.6 106.081V212.857C406.6 216.925 406.6 218.958 405.66 220.489C404.965 221.621 403.925 222.552 402.661 223.174C400.951 224.016 398.679 224.016 394.136 224.016H305.064C300.52 224.016 298.248 224.016 296.538 223.174C295.274 222.552 294.234 221.621 293.54 220.489C292.6 218.958 292.6 216.925 292.6 212.857V106.081Z"
          fill={colors?.bg_color}
        />
        <path
          d="M300.2 136.688C300.2 134.591 301.902 132.891 304 132.891C306.099 132.891 307.8 134.591 307.8 136.688C307.8 138.784 306.099 140.484 304 140.484C301.902 140.484 300.2 138.784 300.2 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M300.2 151.875C300.2 149.778 301.902 148.078 304 148.078C306.099 148.078 307.8 149.778 307.8 151.875C307.8 153.972 306.099 155.672 304 155.672C301.902 155.672 300.2 153.972 300.2 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M300.2 167.062C300.2 164.966 301.902 163.266 304 163.266C306.099 163.266 307.8 164.966 307.8 167.062C307.8 169.159 306.099 170.859 304 170.859C301.902 170.859 300.2 169.159 300.2 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M300.2 182.25C300.2 180.153 301.902 178.453 304 178.453C306.099 178.453 307.8 180.153 307.8 182.25C307.8 184.347 306.099 186.047 304 186.047C301.902 186.047 300.2 184.347 300.2 182.25Z"
          fill="#9F9DA5"
        />
        <path
          d="M312.866 136.688C312.866 134.591 314.568 132.891 316.666 132.891H332.5C334.598 132.891 336.3 134.591 336.3 136.688C336.3 138.784 334.598 140.484 332.5 140.484H316.666C314.568 140.484 312.866 138.784 312.866 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M312.866 151.875C312.866 149.778 314.568 148.078 316.666 148.078H332.5C334.598 148.078 336.3 149.778 336.3 151.875C336.3 153.972 334.598 155.672 332.5 155.672H316.666C314.568 155.672 312.866 153.972 312.866 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M312.866 167.062C312.866 164.966 314.568 163.266 316.666 163.266H341.05C343.148 163.266 344.85 164.966 344.85 167.062C344.85 169.159 343.148 170.859 341.05 170.859H316.666C314.568 170.859 312.866 169.159 312.866 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M312.866 182.25C312.866 180.153 314.568 178.453 316.666 178.453H358.15C360.248 178.453 361.95 180.153 361.95 182.25C361.95 184.347 360.248 186.047 358.15 186.047H316.666C314.568 186.047 312.866 184.347 312.866 182.25Z"
          fill="#9F9DA5"
        />
        <path
          d="M386.017 136.688C386.017 134.591 387.718 132.891 389.817 132.891H395.2C397.299 132.891 399 134.591 399 136.688C399 138.784 397.299 140.484 395.2 140.484H389.817C387.718 140.484 386.017 138.784 386.017 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M385.858 151.875C385.858 149.778 387.56 148.078 389.658 148.078H395.2C397.299 148.078 399 149.778 399 151.875C399 153.972 397.299 155.672 395.2 155.672H389.658C387.56 155.672 385.858 153.972 385.858 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M385.858 167.062C385.858 164.966 387.56 163.266 389.658 163.266L395.2 163.266C397.299 163.266 399 164.966 399 167.062C399 169.159 397.299 170.859 395.2 170.859H389.658C387.56 170.859 385.858 169.159 385.858 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M390.45 182.25C390.45 180.153 392.152 178.453 394.25 178.453H395.2C397.299 178.453 399 180.153 399 182.25C399 184.347 397.299 186.047 395.2 186.047H394.25C392.152 186.047 390.45 184.347 390.45 182.25Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M393.934 110.109C393.934 108.711 395.068 107.578 396.467 107.578C397.866 107.578 399 108.711 399 110.109C399 111.507 397.866 112.641 396.467 112.641C395.068 112.641 393.934 111.507 393.934 110.109Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M393.934 208.828C393.934 207.43 395.068 206.297 396.467 206.297C397.866 206.297 399 207.43 399 208.828C399 210.226 397.866 211.359 396.467 211.359C395.068 211.359 393.934 210.226 393.934 208.828Z"
          fill="#9F9DA5"
        />
        <path
          d="M320.467 106.312C320.467 104.216 322.168 102.516 324.267 102.516H351.817C353.915 102.516 355.617 104.216 355.617 106.312C355.617 108.409 353.915 110.109 351.817 110.109H324.267C322.168 110.109 320.467 108.409 320.467 106.312Z"
          fill="#9F9DA5"
        />
        <path
          d="M320.467 205.031C320.467 202.934 322.168 201.234 324.267 201.234H351.817C353.915 201.234 355.617 202.934 355.617 205.031C355.617 207.128 353.915 208.828 351.817 208.828H324.267C322.168 208.828 320.467 207.128 320.467 205.031Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.4"
          d="M320.467 115.172C320.467 113.774 321.601 112.641 323 112.641H344.85C346.249 112.641 347.383 113.774 347.383 115.172C347.383 116.57 346.249 117.703 344.85 117.703H323C321.601 117.703 320.467 116.57 320.467 115.172Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.4"
          d="M320.467 213.891C320.467 212.493 321.601 211.359 323 211.359H344.85C346.249 211.359 347.383 212.493 347.383 213.891C347.383 215.289 346.249 216.422 344.85 216.422H323C321.601 216.422 320.467 215.289 320.467 213.891Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.16"
          d="M300.2 108.743C300.2 106.473 300.2 105.338 300.67 104.483C301.018 103.852 301.538 103.332 302.17 102.985C303.025 102.516 304.16 102.516 306.432 102.516H309.168C311.44 102.516 312.576 102.516 313.431 102.985C314.063 103.332 314.583 103.852 314.93 104.483C315.4 105.338 315.4 106.473 315.4 108.743V111.476C315.4 113.746 315.4 114.881 314.93 115.735C314.583 116.367 314.063 116.886 313.431 117.233C312.576 117.703 311.44 117.703 309.168 117.703H306.432C304.16 117.703 303.025 117.703 302.17 117.233C301.538 116.886 301.018 116.367 300.67 115.735C300.2 114.881 300.2 113.746 300.2 111.476V108.743Z"
          fill={colors?.primary_color}
        />
        <path
          opacity="0.16"
          d="M300.2 207.461C300.2 205.191 300.2 204.056 300.67 203.202C301.018 202.571 301.538 202.051 302.17 201.704C303.025 201.234 304.16 201.234 306.432 201.234H309.168C311.44 201.234 312.576 201.234 313.431 201.704C314.063 202.051 314.583 202.571 314.93 203.202C315.4 204.056 315.4 205.191 315.4 207.461V210.195C315.4 212.465 315.4 213.6 314.93 214.454C314.583 215.086 314.063 215.605 313.431 215.952C312.576 216.422 311.44 216.422 309.168 216.422H306.432C304.16 216.422 303.025 216.422 302.17 215.952C301.538 215.605 301.018 215.086 300.67 214.454C300.2 213.6 300.2 212.465 300.2 210.195V207.461Z"
          fill={colors?.primary_color}
        />
        <path
          d="M302.733 110.109C302.733 108.337 302.733 107.451 303.079 106.774C303.382 106.179 303.867 105.695 304.462 105.392C305.14 105.047 306.027 105.047 307.8 105.047C309.574 105.047 310.46 105.047 311.138 105.392C311.734 105.695 312.218 106.179 312.522 106.774C312.867 107.451 312.867 108.337 312.867 110.109C312.867 111.881 312.867 112.767 312.522 113.444C312.218 114.04 311.734 114.524 311.138 114.827C310.46 115.172 309.574 115.172 307.8 115.172C306.027 115.172 305.14 115.172 304.462 114.827C303.867 114.524 303.382 114.04 303.079 113.444C302.733 112.767 302.733 111.881 302.733 110.109Z"
          fill={colors?.primary_color}
        />
        <path
          d="M302.733 208.828C302.733 207.056 302.733 206.17 303.079 205.493C303.382 204.898 303.867 204.414 304.462 204.11C305.14 203.766 306.027 203.766 307.8 203.766C309.574 203.766 310.46 203.766 311.138 204.11C311.734 204.414 312.218 204.898 312.522 205.493C312.867 206.17 312.867 207.056 312.867 208.828C312.867 210.6 312.867 211.486 312.522 212.163C312.218 212.758 311.734 213.242 311.138 213.546C310.46 213.891 309.574 213.891 307.8 213.891C306.027 213.891 305.14 213.891 304.462 213.546C303.867 213.242 303.382 212.758 303.079 212.163C302.733 211.486 302.733 210.6 302.733 208.828Z"
          fill={colors?.primary_color}
        />
        <line
          x1="300.7"
          y1="124.797"
          x2="398.5"
          y2="124.797"
          stroke={colors?.secondaryLightPurple}
          strokeLinecap="round"
        />
        <line
          x1="300.7"
          y1="193.141"
          x2="398.5"
          y2="193.141"
          stroke={colors?.secondaryLightPurple}
          stroke-strokeLinecap="round"
        />
        <rect
          x="57"
          y="105.996"
          width="60.1667"
          height="10.125"
          rx="5.0625"
          fill="#9F9DA5"
        />
        <path
          d="M211.533 111.059C211.533 108.962 213.235 107.262 215.333 107.262H232.117C234.215 107.262 235.917 108.962 235.917 111.059C235.917 113.156 234.215 114.855 232.117 114.855H215.333C213.235 114.855 211.533 113.156 211.533 111.059Z"
          fill={colors?.primary_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.342 104.098H210.166C209.019 104.098 208.199 104.098 207.558 104.154C206.925 104.209 206.526 104.314 206.208 104.489C205.681 104.778 205.248 105.211 204.959 105.737C204.784 106.055 204.678 106.454 204.623 107.086C204.567 107.727 204.567 108.546 204.567 109.692V112.425C204.567 113.572 204.567 114.391 204.623 115.031C204.678 115.663 204.784 116.062 204.959 116.38C205.248 116.906 205.681 117.339 206.208 117.628C206.526 117.803 206.925 117.908 207.558 117.963C208.199 118.019 209.019 118.02 210.166 118.02H237.342C238.489 118.02 239.309 118.019 239.95 117.963C240.582 117.908 240.981 117.803 241.299 117.628C241.826 117.339 242.259 116.906 242.549 116.38C242.724 116.062 242.829 115.663 242.884 115.031C242.94 114.391 242.941 113.572 242.941 112.425V109.692C242.941 108.546 242.94 107.727 242.884 107.086C242.829 106.454 242.724 106.055 242.549 105.737C242.259 105.211 241.826 104.778 241.299 104.489C240.981 104.314 240.582 104.209 239.95 104.154C239.309 104.098 238.489 104.098 237.342 104.098ZM204.404 105.433C203.934 106.287 203.934 107.422 203.934 109.692V112.425C203.934 114.695 203.934 115.83 204.404 116.685C204.751 117.316 205.271 117.836 205.903 118.183C206.758 118.652 207.894 118.652 210.166 118.652H237.342C239.614 118.652 240.75 118.652 241.605 118.183C242.236 117.836 242.757 117.316 243.104 116.685C243.574 115.83 243.574 114.695 243.574 112.425V109.692C243.574 107.422 243.574 106.287 243.104 105.433C242.757 104.801 242.236 104.282 241.605 103.934C240.75 103.465 239.614 103.465 237.342 103.465H210.166C207.894 103.465 206.758 103.465 205.903 103.934C205.271 104.282 204.751 104.801 204.404 105.433Z"
          fill={colors?.primary_color}
        />
        <rect
          x="57"
          y="125.297"
          width="220.4"
          height="123.398"
          rx="5"
          fill={colors?.secondaryLightPurple}
        />
        <rect
          x="57"
          y="253.758"
          width="220.4"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          x="57"
          y="261.352"
          width="220.4"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          x="57"
          y="268.945"
          width="220.4"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          x="57"
          y="276.539"
          width="170.683"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <path
          d="M173.834 171.176H160.565C154.802 171.176 151.366 174.609 151.366 180.367V193.609C151.366 199.383 154.802 202.816 160.565 202.816H173.818C179.581 202.816 183.017 199.383 183.017 193.625V180.367C183.033 174.609 179.597 171.176 173.834 171.176ZM171.411 189.733L169.385 190.904L167.358 192.074C164.745 193.577 162.608 192.343 162.608 189.337V186.996V184.655C162.608 181.633 164.745 180.415 167.358 181.918L169.385 183.088L171.411 184.259C174.024 185.762 174.024 188.23 171.411 189.733Z"
          fill={colors?.primary_color}
        />
        <path
          d="M57 51.8906C57 49.7937 58.7013 48.0938 60.8 48.0938H67.7667C69.8653 48.0938 71.5667 49.7937 71.5667 51.8906C71.5667 53.9876 69.8653 55.6875 67.7667 55.6875H60.8C58.7013 55.6875 57 53.9876 57 51.8906Z"
          fill={colors?.primary_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.9351 44.9297H55.6324C54.4853 44.9297 53.6657 44.9302 53.0247 44.986C52.3919 45.0411 51.9931 45.1463 51.6748 45.3211C51.1483 45.6103 50.7149 46.0433 50.4254 46.5695C50.2505 46.8874 50.1452 47.2859 50.0901 47.9182C50.0343 48.5586 50.0337 49.3776 50.0337 50.5238V53.2575C50.0337 54.4036 50.0343 55.2226 50.0901 55.8631C50.1452 56.4953 50.2505 56.8938 50.4254 57.2118C50.7149 57.7379 51.1483 58.1709 51.6748 58.4602C51.9931 58.635 52.3919 58.7402 53.0247 58.7952C53.6657 58.851 54.4853 58.8516 55.6324 58.8516H72.9351C74.0821 58.8516 74.9018 58.851 75.5428 58.7952C76.1756 58.7402 76.5744 58.635 76.8926 58.4602C77.4192 58.1709 77.8525 57.7379 78.142 57.2118C78.317 56.8938 78.4223 56.4953 78.4774 55.8631C78.5332 55.2226 78.5337 54.4036 78.5337 53.2575V50.5238C78.5337 49.3776 78.5332 48.5586 78.4774 47.9182C78.4223 47.2859 78.317 46.8874 78.142 46.5695C77.8525 46.0433 77.4192 45.6103 76.8926 45.3211C76.5744 45.1463 76.1756 45.0411 75.5428 44.986C74.9018 44.9302 74.0821 44.9297 72.9351 44.9297ZM49.8704 46.2646C49.4004 47.1189 49.4004 48.2538 49.4004 50.5238V53.2575C49.4004 55.5274 49.4004 56.6624 49.8704 57.5167C50.2178 58.148 50.7378 58.6676 51.3697 59.0147C52.2247 59.4844 53.3606 59.4844 55.6324 59.4844H72.9351C75.2068 59.4844 76.3427 59.4844 77.1977 59.0147C77.8296 58.6676 78.3496 58.148 78.697 57.5167C79.1671 56.6624 79.1671 55.5274 79.1671 53.2575V50.5238C79.1671 48.2538 79.1671 47.1189 78.697 46.2646C78.3496 45.6332 77.8296 45.1136 77.1977 44.7665C76.3427 44.2969 75.2068 44.2969 72.9351 44.2969H55.6324C53.3606 44.2969 52.2247 44.2969 51.3697 44.7665C50.7378 45.1136 50.2178 45.6332 49.8704 46.2646Z"
          fill={colors?.primary_color}
        />
        <path
          d="M49.7168 73.4062C49.7168 71.3093 51.4181 69.6094 53.5168 69.6094H115.9C117.999 69.6094 119.7 71.3093 119.7 73.4062C119.7 75.5032 117.999 77.2031 115.9 77.2031H53.5168C51.4181 77.2031 49.7168 75.5032 49.7168 73.4062Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M49.7168 84.1641C49.7168 82.7661 50.851 81.6328 52.2501 81.6328H76.6335C78.0326 81.6328 79.1668 82.7661 79.1668 84.1641C79.1668 85.562 78.0326 86.6953 76.6335 86.6953H52.2501C50.851 86.6953 49.7168 85.562 49.7168 84.1641Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M86.7666 84.1641C86.7666 82.7661 87.9008 81.6328 89.2999 81.6328H113.683C115.082 81.6328 116.217 82.7661 116.217 84.1641C116.217 85.562 115.082 86.6953 113.683 86.6953H89.2999C87.9008 86.6953 86.7666 85.562 86.7666 84.1641Z"
          fill="#9F9DA5"
        />
        <path d="M0 25.3125H456V36.7031H0V25.3125Z" fill={colors?.bg_color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 25.3125V36.7031H456V36.3867H0.316667V25.3125H0Z"
          fill={colors?.secondaryLightPurple}
        />
        <path d="M0 0H456V25.3125H0V0Z" fill={colors?.bg_color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V25.3125H456V24.9961H0.316667V0H0Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          opacity="0.16"
          d="M406.6 12.6562C406.6 16.8502 403.198 20.25 399 20.25C394.803 20.25 391.4 16.8502 391.4 12.6562C391.4 8.46234 394.803 5.0625 399 5.0625C403.198 5.0625 406.6 8.46234 406.6 12.6562Z"
          fill={colors?.primary_color}
        />
        <path
          d="M402.8 12.6562C402.8 14.7532 401.099 16.4531 399 16.4531C396.902 16.4531 395.2 14.7532 395.2 12.6562C395.2 10.5593 396.902 8.85938 399 8.85938C401.099 8.85938 402.8 10.5593 402.8 12.6562Z"
          fill={colors?.primary_color}
        />
        <rect
          x="49.4004"
          y="8.85938"
          width="52.25"
          height="7.59375"
          rx="3.79688"
          fill={colors?.primary_color}
        />
        <rect
          x="218.5"
          y="10.125"
          width="45.9167"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <path
          d="M49.7168 31.0078C49.7168 29.6098 50.851 28.4766 52.2501 28.4766H72.5168C73.9159 28.4766 75.0501 29.6098 75.0501 31.0078C75.0501 32.4058 73.9159 33.5391 72.5168 33.5391H52.2501C50.851 33.5391 49.7168 32.4058 49.7168 31.0078Z"
          fill="#9F9DA5"
        />
        <rect
          x="85.1836"
          y="28.4766"
          width="25.3333"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          x="120.65"
          y="28.4766"
          width="34.2"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          opacity="0.75"
          x="164.983"
          y="28.4766"
          width="30.4"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          x="166.25"
          y="10.125"
          width="31.9833"
          height="5.0625"
          rx="2.53125"
          fill={colors?.primary_color}
        />
        <rect
          x="284.684"
          y="10.125"
          width="24.3833"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          opacity="0.75"
          x="373.667"
          y="8.85938"
          width="7.6"
          height="7.59375"
          rx="3.79688"
          fill="#9F9DA5"
        />
        <rect
          x="1.5"
          y="0.5"
          width="454"
          height="324"
          rx="11.5"
          stroke={colors?.secondaryLightPurple}
        />
      </g>
      <defs>
        <clipPath id="clip0_18699_43046">
          <rect width="456" height="325" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
